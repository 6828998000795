<template>
    <div class="case-item case-item-detail">

        <div v-if="item && item.id ">

            <div class="case-item-head">

                <div class="user-info">

                    <img class="avatar" :src="item.ast_member.avatar" alt=""/>

                    <div>
                        <div class="user-name">{{ item.ast_member.name }}</div>
                        <div class="user-time">{{ formatTime(item.created_at) }}</div>
                    </div>

                </div>

            </div>

            <div class="case-item-title">
                <div>{{ item.title }}</div>
            </div>

            <div class="case-item-bottom tags-list">
                <template v-for="(tag,tk) in item.tags ">
                    <div :key="tk" class="case-tag" @tap="gotoTag(tag)">#{{ tag.title }}</div>
                </template>
            </div>

            <div class="case-item-summary">
                <div class="text">
                    <pre>{{ item.summary }}</pre>
                </div>
            </div>

            <div v-if="item.images && item.images.length" class="case-item-images">

                <template v-for=" (image,idx) in item.images ">

                    <div class="cate-item-image" :key="idx">
                        <img :src="image" alt=""/>
                    </div>

                </template>

                <div class="cate-item-image"></div>
                <div class="cate-item-image"></div>
            </div>


            <div class="case-item-bottom">
                <div class="case-tag" v-if="item.course">{{ item.course.title }}</div>
            </div>
        </div>

    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "case-detail",
    props: {
        item: Object
    },
    data() {
        return {};
    },
    methods: {
        formatTime(time) {
            return moment(time).format('yyyy-MM-DD HH:mm')
        }
    }
}
</script>

<style lang="less" scoped>
.case-item-detail {
    padding-left: 12px;
    padding-right: 12px;
}

.case-item {

    box-sizing: border-box;

    .user-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        position: relative;

        .avatar {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            margin-right: 16px;
        }

        .right-icon {

            height: 15px;
            position: absolute;
            right: 0;
            top: 6px;
        }
    }

    .user-name {
        font-weight: 600;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        margin-bottom: 4px;
    }

    .user-time {
        font-weight: 400;
        font-size: 12px;
        color: #999999;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        margin-left: 1px;
    }
}

.case-item-title {
    font-weight: 500;
    font-size: 15px;
    color: #222222;
    line-height: 30px;
    text-align: left;
    font-style: normal;
    margin-top: 8px;

    .case-tag {
        margin-right: 7px;
    }
}

.case-tag {
    font-weight: 400;
    font-size: 12px;
    color: #DF8C71;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    padding: 4px 7px;
    background: rgba(252, 182, 159, 0.1);
    border-radius: 5px;
    display: inline-block;
}

.case-item-images {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
}

.cate-item-image {
    img {
        width: 100%;
        border: 1px solid #dddddd38;
        border-radius: 5px;
    }
}

pre{
    word-break: break-word;
    white-space: pre-wrap;
}


.case-item-summary {
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    margin-top: 8px;
}



.detail-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
}

.case-item-bottom {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bottom-btn {
        border: none;
        background-color: transparent;
        outline: none;
        height: 12px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 22px;

        &:after {
            border: none;
        }
    }

    .bottom-btn:last-child {
        margin-left: 40px;
    }

    .bottom-btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}


.tags-list {
    justify-content: flex-start;

    .case-tag {
        background-color: transparent;
    }
}
</style>