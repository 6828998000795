<template>
    <div>
        <div class="page-title">案例&分享
            <button class="btn" @click="gotoTags">标签管理</button>
        </div>

        <div class="search-tab">

            <at-input v-model="query.keywords" class="mb-4" width="500px" placeholder="按用户id，昵称搜索">
                <button @click="getFirstList" class="btn">搜索</button>
            </at-input>

        </div>


        <a-table :columns="table.columns"
                 :rowKey="record => record.id || record.key"
                 :data-source="table.data"
                 :pagination="table.pagination"
                 @change="tableChanged"
                 :locale="{emptyText:'暂无案例'}"
                 class="members-table"
                 :loading="table.loading"
        >

            <div slot="member" slot-scope="member,item">
                <router-link class="user-info" :to="`/ast/members/${member.id}`">

                    <img class="avatar" :src="member.avatar" alt=""/>

                    <div>
                        <div class="user-name">
                            <span>{{ member.mark_name || member.name }}</span>
                            <small> [{{ member.member_id }}]</small>
                        </div>
                        <div class="user-time">{{ formatTime(item.created_at) }}</div>
                    </div>

                </router-link>
            </div>

            <div slot="tags" slot-scope="tags,item">
                <div class="cursor-pointer" @click="activeTagModal(item)">
                    <template v-if="tags.length">
                        <template v-for="(tag,tk) in tags">

                            <div :key="tk" class="case-tag">{{ tag.title }}</div>

                        </template>
                    </template>
                    <template v-else>
                        <span class="case-tag case-tag-none">未选择标签</span>
                    </template>
                </div>
            </div>

            <div slot="course" slot-scope="course,item">
                <div class="cursor-pointer" @click="activeCourseModal(item)">
                    <template v-if="course">
                        <div class="case-tag">{{ course.title }}</div>
                    </template>
                    <template v-else>
                        <div class="case-tag case-tag-none">未选择服务</div>
                    </template>
                </div>
            </div>

            <div class="badges" slot="status" slot-scope="val,item">

                <div @click="showReview(item)">
                    <template v-if="item.status === 1 ">
                        <a-badge color="green" text="已通过"/>
                    </template>
                    <template v-else-if="item.status === 2">
                        <a-badge color="red" text="已拒绝"/>
                    </template>
                    <template v-else-if="item.status === 0">
                        <a-badge color="orange" text="待审核"/>
                    </template>
                </div>


                <div class="mt-4" v-if=" item.recommend === 1 ">
                    <a-badge color="lime" text="推荐"/>
                </div>
            </div>


            <div slot="op" slot-scope="val,item">
                <template v-if="item.status === 0">
                    <div class="mb-4 op-btn" @click="showReview(item)">
                        <a>审核</a>
                    </div>
                </template>
                <template v-else>
                    <div class="mb-4 op-btn" @click="showReview(item)">
                        <a>设置</a>
                    </div>
                </template>

                <div v-if=" item.status === 1 " class="op-btn" @click="recommend(item)">
                    <a>{{ item.recommend === 1 ? '取消推荐' : '设置推荐' }}</a>
                </div>
            </div>

            <div slot="title" slot-scope="val,item">

                <template v-if="item">
                    <a @click="showDetail(item)">{{ item.title }}</a>
                </template>

            </div>

        </a-table>

        <a-modal title="案例详情" v-model="showDetailModal" :footer="null">
            <template v-if="detail && detail.id ">

                <case-detail :item="detail"/>

            </template>
        </a-modal>

        <a-modal v-model="showReviewModal" :footer="null">
            <div class="review-title">
                <a-icon class="icon" type="question-circle"></a-icon>
                <span>本案例&分享是否可在手机端显示？</span>
            </div>
            <div>
                <div class="review-btns">
                    <a-button @click="review(2)" class="review-btn" type="danger">拒绝</a-button>
                    <a-button @click="review(1)" class="review-btn" type="primary">通过</a-button>
                </div>
            </div>
        </a-modal>

        <a-modal v-model="showTagsModal" title="修改标签" @ok="syncCaseTags">

            <div class="tags-list" v-if="tags.length">

                <template v-for="(tag,tk) in tags">
                    <div @click="switchTag(tag)" class="case-tag selector"
                         :class="{ selected:selectTagIds.indexOf(tag.id) >= 0 }"
                         :key="tk">{{ tag.title }}
                    </div>
                </template>

            </div>
            <div v-else>
                <span>暂无标签，请先去添加标签</span>
            </div>

        </a-modal>

        <a-modal v-model="showCourseModal" title="修改归属产品" @ok="confirmCourse">
            <a-select
                    class="full-with"
                    show-search
                    v-model="courseId"
                    placeholder="输入搜索关键词"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="courseSearch"
            >

                <a-select-option v-for="d in courses" :key="d.id">
                    {{ d.title }}
                </a-select-option>
            </a-select>
        </a-modal>
    </div>
</template>

<script>

import api from "../../../repo/api";
import AtInput from "@/components/AtInput.vue";
import moment from "moment/moment";
import CaseDetail from "@/views/AST/Serve/com/caseDetail.vue";

export default {
    components: {CaseDetail, AtInput},
    data() {
        return {
            query: {
                status: 1
            },
            detail: null,
            showDetailModal: false,
            showReviewModal: false,
            showTagsModal: false,
            showCourseModal: false,
            table: {
                columns: [
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        width: '110px',
                    },
                    {
                        title: '发布用户',
                        dataIndex: 'ast_member',
                        scopedSlots: {customRender: 'member'},
                    },
                    {
                        title: '标题',
                        dataIndex: 'title',
                        scopedSlots: {customRender: 'title'},
                        width: "230px",
                    }, {
                        title: '绑定标签',
                        dataIndex: 'tags',
                        scopedSlots: {customRender: 'tags'},
                        width: "230px",
                    },
                    {
                        title: '归属产品',
                        dataIndex: 'course',
                        scopedSlots: {customRender: 'course'},
                    },
                    {
                        title: '状态',
                        dataIndex: 'status',
                        width: "100px",
                        scopedSlots: {customRender: 'status'},
                    }, {
                        title: '操作',
                        dataIndex: 'op',
                        width: "100px",
                        scopedSlots: {customRender: 'op'},
                    },

                ],
                loading: true,
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 15,
                    showTotal: (total, range) => {
                        return `当前${range[0]}-${range[1]}，共 ${total} 个案例`;
                    },
                },
                data: []
            },
            tags: [],
            selectTagIds: [],
            courses: [],
            courseId: undefined,
        }
    },
    mounted() {
        this.getFirstList()
        this.getTags()
    },
    methods: {
        activeCourseModal(item) {
            this.courseId = undefined;
            this.detail = item

            if (this.courses.length) {
                this.showCourseModal = true
                return;
            }

            this.$loading.show()

            this.fetchCourses('').then(() => {
                this.showCourseModal = true
                this.$loading.hide()
            })
        },
        courseSearch(e) {
            this.fetchCourses(e)
        },
        confirmCourse() {

            this.$loading.show()

            api.post('/ast-cases/sync-case-course', {
                id: this.detail.id,
                course_id: this.courseId
            }, (res) => {

                this.$loading.hide()
                this.$message.data(res)

                if (res.code === 0) {
                    this.showCourseModal = false
                    this.getList()
                }
            })
        },
        fetchCourses(keywords) {

            return new Promise((resolve) => {
                api.get('/course/list', {keywords}, (res) => {
                    this.courses = res.data.data
                    console.log(this.courses);
                    resolve(res.data.data)
                })
            })

        },
        activeTagModal(item) {

            let selectIds = []

            item.tags.forEach(tag => {
                selectIds.push(tag.id)
            })

            this.selectTagIds = selectIds
            this.detail = item

            this.showTagsModal = true
        },
        gotoTags() {
            this.$router.push('/ast/cases/tags')
        },
        showDetail(item) {
            this.detail = item
            this.showDetailModal = true
        },
        formatTime(time) {
            return moment(time).format('yyyy-MM-DD HH:mm')
        },
        getFirstList() {
            this.query.page = 1
            this.getList()
        },
        getTags() {
            api.get('/ast-cases/all-tags', this.query, (data) => {
                this.tags = data.data || []
            })
        },
        getList() {

            this.table.loading = true

            api.get('/ast-cases/list', this.query, (data) => {

                this.table.data = data.data.data
                this.table.pagination.current = data.data.current_page
                this.table.pagination.total = data.data.total
                this.table.pagination.pageSize = data.data.per_page

                this.table.loading = false

            })
        },
        tableChanged(pagination) {
            this.query.page = pagination.current
            this.getList()
        },
        showReview(item) {
            this.detail = item
            this.showReviewModal = true
        },
        switchTag(tag) {
            let idx = this.selectTagIds.indexOf(tag.id)

            if (idx >= 0) return this.selectTagIds.splice(idx, 1)

            if (this.selectTagIds.length >= 5) return;

            this.selectTagIds.push(tag.id)
        },
        syncCaseTags() {
            this.$loading.show()

            api.post('/ast-cases/sync-case-tags', {id: this.detail.id, tags: this.selectTagIds}, (res) => {

                this.$loading.hide()

                this.$message.data(res)

                if (res.code === 0) {
                    this.showTagsModal = false
                    this.getList()
                }
            })
        },
        review(status) {

            this.$loading.show()

            api.post('/ast-cases/review', {id: this.detail.id, val: status}, (res) => {

                this.$loading.hide()

                this.$message.data(res)

                if (res.code === 0) {
                    this.showReviewModal = false
                    this.getList()
                }
            })

        },
        recommend(item) {
            this.$loading.show()

            api.post('/ast-cases/recommend', {id: item.id, val: item.recommend === 1 ? 0 : 1}, (res) => {

                this.$loading.hide()

                this.$message.data(res)

                if (res.code === 0) this.getList()
            })
        },
    }
}
</script>
<style lang="less" scoped>
.user-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    position: relative;

    .user-name {
        margin-bottom: 12px;
    }

    .avatar {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        margin-right: 16px;
    }

    .right-icon {
        height: 15px;
        position: absolute;
        right: 0;
        top: 6px;
    }
}

.members-table /deep/ table {
    .ant-table-thead > tr > th {
        background: transparent;
        padding: 12px 12px;
    }

    .ant-table-column-title {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
    }

    .ant-table-tbody > tr > td {
        font-size: 14px;
        font-weight: 400;
        color: #606266;
        line-height: 14px;
        padding: 12px 12px;
    }
}

.search-tab {
    display: flex;
    justify-content: space-between;
}

.sd-item {
    margin-top: 32px;

    &:first-child {
        margin-top: 0;
    }
}

.case-tag {
    font-weight: 400;
    font-size: 12px;
    color: #DF8C71;
    line-height: 15px;
    text-align: left;
    font-style: normal;
    padding: 4px 7px;
    background: rgba(252, 182, 159, 0.1);
    border-radius: 5px;
    display: inline-block;

    &.case-tag-none {
        color: #ddd;
        background-color: transparent;
    }

    &.selector {
        font-size: 16px;
        padding: 9px 12px;
        margin-right: 12px;
        cursor: pointer;
        margin-bottom: 12px;
    }

    &.selected {
        background-color: #DF8C71;
        color: white;
    }
}

.op-btn {
    font-size: 12px;
}

.badges {
    font-size: 12px;

    /deep/ .ant-badge-status-text {
        font-size: 12px;
    }
}

.mt-4 {
    margin-top: 8px;
}

.review-title {
    display: block;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;

    .icon {
        margin-right: 12px;
        color: orange;
    }
}

.review-btns {
    display: flex;
    margin-right: -12px;
    margin-top: 12px;
    justify-content: flex-end;

    .review-btn {
        margin-right: 12px;
    }
}

.page-title {
    .btn {
        float: right;
    }
}
</style>